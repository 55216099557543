import Vue from 'vue';
import MConnection from '@manifoldxyz/connect-widget';
import manifoldCSSReset from '@manifoldxyz/css-reset';
import 'tailwindcss/dist/tailwind.css';
import App from './App.vue';
import router from './router';

manifoldCSSReset();

Vue.use(MConnection.SimpleConnect);
Vue.use(MConnection.ConnectMenu);

Vue.config.productionTip = false;

new Vue({
  router,
  render: h => h(App)
}).$mount('#app');
