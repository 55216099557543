export enum TransactionErrors {
  REJECTED,
  REPLACED,
  CANCELLED,
  EXCEPTION,
  LEDGER_ERROR,
  OTHER
}

export interface transactionError {
  replacement: any,
  cancelled: boolean,
  code: string|number,
  message: string
}

/*
 * Web 3 transaction error handling
 * @dev Pass in the error returned by things like waitForTransaction in ethers
 * It will return a more nuanced enum for you to switch/if/else through
 */
const web3TransactionErrorHandling = (
  // TODO: Replace with Ethers error object, this must exist somewhere
  error: transactionError
) : TransactionErrors => {
  // WARN: It is weird that error.code can be either a string or a number
  if (error.code === 4001) {
    return TransactionErrors.REJECTED;
  } else if (error.code === 'TRANSACTION_REPLACED' && error.replacement && !error.cancelled) { // Check if the trasaction was sped up
    console.warn('Transaction replaced');
    return TransactionErrors.REPLACED;
  } else if (error.code === 'TRANSACTION_REPLACED' && error.cancelled) {
    console.warn('Transaction cancelled');
    return TransactionErrors.CANCELLED;
  } else if (error.code === 'CALL_EXCEPTION') { // transaction was cancelled
    return TransactionErrors.EXCEPTION;
  } else if (error.message.includes('Ledger device')) {
    return TransactionErrors.LEDGER_ERROR;
  } else {
    return TransactionErrors.OTHER;
  }
};

export default web3TransactionErrorHandling;
