
















































































































































































import { Component, Vue } from 'vue-property-decorator';
import { ERC1155ABI } from '@manifoldxyz/manifold-contracts-sdk';
import { EthereumNetwork, EthereumProvider } from '@manifoldxyz/manifold-sdk';
import SicilianKissBurn from '@/assets/SicilianKissBurn.json';
import { BURNABLE_KISS_ID, CREATOR_CONTRACT_ADDRESS, EXTENSION_CONTRACT_ADDRESS, FALLBACK_PROVIDER, NETWORK } from '@/common/constants';
import web3TransactionErrorHandling, { TransactionErrors } from '@/common/errorHandling';

@Component
export default class Home extends Vue {
  creatorContract: any;
  extensionContract: any;
  account: string | undefined = ''
  shortenedAccount = ''
  nfts = 0;

  fallbackProvider = FALLBACK_PROVIDER;
  network = NETWORK;

  approvalTransastionHash = ''
  redeemTransastionHash = ''
  step = ''
  errorMessage = ''
  isRequesting = false
  isApproving = false
  isRedeeming = false
  didRedeem = false
  progress = 0
  burnAmount = 15;
  tab = 0;
  enableBurnRedeemButton = false;
  isWaitingForApproving = false;
  isWaitingForMint = false;
  burnIsActive = false;
  burnDeactivationTimestamp = 0;
  countdown = '';
  burnEndCountdown = '';
  countdownInterval: number;
  burnEndCountdownInterval: number;

  get maxBurnable (): number {
    if (this.nfts) {
      return Math.floor(this.nfts / 15);
    }

    return 0;
  }

  async mounted () : Promise<void> {
    this.updateCountdownString();
    this.updateBurnEndCountdownString();

    // m-connect event
    window.addEventListener(EthereumProvider.ADDRESS_CHANGED, async () => {
      this.account = EthereumProvider.selectedAddress();
      if (this.account) {
        this.shortenedAccount = `${this.account.substring(0, 3)}...${this.account.substring(this.account.length - 4, this.account.length)}`;
        await this.findNFTs();
      } else {
        localStorage.removeItem('connectedAddress');
        this.account = '';
        this.shortenedAccount = '';
        this.nfts = 0;
      }
    });

    this.countdownInterval = window.setInterval(this.updateCountdownString, 1000);
    this.burnEndCountdownInterval = window.setInterval(this.updateBurnEndCountdownString, 2000);

    EthereumProvider.initialize(NETWORK as any as EthereumNetwork);

    const prevConnectAddress = localStorage.getItem('connectedAddress');

    if (prevConnectAddress) {
      this.account = prevConnectAddress;
      this.shortenedAccount = `${this.account.substring(0, 3)}...${this.account.substring(this.account.length - 4, this.account.length)}`;
    }
  }

  async created () : Promise<void> {
    if (NETWORK) {
      this.network = NETWORK;
    }
  }

  openImage (image: number) : void {
    if (image === 1) { window.open('https://arweave.net/u20vT9AUeRQVgkOXk7L7V0vWeiD4GESuq6SBA93zNSQ', '_blank'); }
    if (image === 2) { window.open('https://arweave.net/pedFvC_vdONT3TAkFioEofuE3N0X82QwbSTWhpzVdtY', '_blank'); }
    if (image === 3) { window.open('https://arweave.net/8gJ0hWWFTOhYtQLQy9ymbVp4BDVrL0Go7hxeDLtSmZQ', '_blank'); }
    if (image === 4) { window.open('https://arweave.net/AlW_B4OPv1LuwJA0AwHbza9qvZapBNEGc74gufxVw9I', '_blank'); }
  }

  async updateCountdownString () : Promise<void> {
    // Get timestamp of right now
    const now = new Date().getTime();
    const goal = new Date(1662739200000).getTime();

    // The countdown is finished
    if (now - goal > 0) {
      clearInterval(this.countdownInterval);
      this.countdown = '';
    }

    // Find the distance between now and the count down date
    const distance = Math.abs(now - goal);

    // Time calculations for days, hours, minutes and seconds
    const days = distance / (1000 * 60 * 60 * 24);
    const hours = (days % 1) * 24;
    const minutes = (hours % 1) * 60;
    const seconds = (minutes % 1) * 60;

    // Display the result in the element with id="demo"
    this.countdown =
      Math.floor(days) + 'd ' +
      Math.floor(hours) + 'h ' +
      Math.floor(minutes) + 'm ' +
      Math.floor(seconds) + 's';
  }

  async updateBurnEndCountdownString () : Promise<void> {
    // Get timestamp of right now
    const now = new Date().getTime();

    // Update the burn timestamp
    if (this.extensionContract && this.extensionContract.deactivationTimestamp) {
      const deactivationTimestamp = await this.extensionContract.deactivationTimestamp();
      this.burnDeactivationTimestamp = deactivationTimestamp * 1000;
      this.burnIsActive = this.burnDeactivationTimestamp > Date.now();
    }

    const goal = this.burnDeactivationTimestamp;

    // Countdown has not started yet, we're inactive
    if (this.burnDeactivationTimestamp === 0) {
      this.burnEndCountdown = 'Burn has not started yet';

      if (!this.account) {
        this.burnEndCountdown = 'Please connect your wallet';
      }
      return;
    }

    // Countdown has finished
    if (now - goal > 0) {
      clearInterval(this.burnEndCountdownInterval);
      this.burnEndCountdown = 'Burn is over!';
      return;
    }

    // Find the distance between now and the count down date
    const distance = Math.abs(now - goal);

    // Time calculations for days, hours, minutes and seconds
    const days = distance / (1000 * 60 * 60 * 24);
    const hours = (days % 1) * 24;
    const minutes = (hours % 1) * 60;
    const seconds = (minutes % 1) * 60;

    // update the string in the UX
    this.burnEndCountdown =
      Math.floor(days) + 'd ' +
      Math.floor(hours) + 'h ' +
      Math.floor(minutes) + 'm ' +
      Math.floor(seconds) + 's';
  }

  changeTab (tab: number) : void {
    this.tab = tab;
  }

  toggleBurnRedeemButton (event: Event) : void {
    const target = event.target as HTMLInputElement;
    this.enableBurnRedeemButton = target.checked;
  }

  /**
   * This method is used to retrieve the NFT data from the Manifold backend.
   * This also does a safety check to make sure that the contract of the NFT
   * they want to burn is whitelisted for redemption.
   *
   * @dev Be sure to update all of the variables in common/constants.js
   *      otherwise this method is sure to error out. If you are in dev
   *      be sure you are running the fake api and that the fake data in
   *      db.json is correct for you use-case.
   */
  async findNFTs () : Promise<boolean> {
    this.nfts = 0;

    try {
      this.creatorContract = EthereumProvider.contractInstance(
        CREATOR_CONTRACT_ADDRESS,
        ERC1155ABI,
        true
      );

      this.extensionContract = EthereumProvider.contractInstance(
        EXTENSION_CONTRACT_ADDRESS,
        SicilianKissBurn.abi,
        true
      );

      if (this.account) {
        this.nfts = (await this.creatorContract.balanceOf(this.account, BURNABLE_KISS_ID)).toNumber();
      }

      if (this.extensionContract && this.extensionContract.deactivationTimestamp) {
        const deactivationTimestamp = await this.extensionContract.deactivationTimestamp();
        this.burnDeactivationTimestamp = deactivationTimestamp * 1000;
        this.burnIsActive = this.burnDeactivationTimestamp > Date.now();
      } else {
        // im testing
        this.burnDeactivationTimestamp = Date.now() + 84000000;
        this.burnIsActive = true;
      }

      return true;
    } catch (e) {
      console.warn('Could not find NFTs. If this is dev, be sure you are running `yarn stubapi`');
      console.warn(e);
      return false;
    }
  }

  /**
   * This method is used to actual burn-redeem the selected NFT in the users
   * wallet.
   *
   * @dev Be sure to update all of the variables in common/constants.js
   *      otherwise this method is sure to error out.
   */
  async redeem () : Promise<void> {
    this.step = 'redeem';
    this.didRedeem = false;
    this.errorMessage = '';
    // show modal for redeeming
    this.isRequesting = true;

    await this.findNFTs();

    if (this.nfts < 15) {
      this.errorMessage = 'You do not own enough kisses!';
      return;
    }

    try {
      // First check if the extension is already approved to burn NFTs
      let approved = false;
      if (this.creatorContract) {
        approved = await this.creatorContract.isApprovedForAll(
          EthereumProvider.selectedAddress(),
          EXTENSION_CONTRACT_ADDRESS
        );

        // If not, let's get it approved
        if (!approved) {
          this.isWaitingForApproving = true;
          const approvalResponse = await this.creatorContract.setApprovalForAll(
            EXTENSION_CONTRACT_ADDRESS,
            {
              from: this.account
            }
          );
          this.isWaitingForApproving = false;

          this.approvalTransastionHash = approvalResponse.hash.toString();

          // show modal for approving
          this.isApproving = true;
          await approvalResponse.wait();
          this.isApproving = false;
        }
      }

      if (this.extensionContract) {
        this.isWaitingForMint = true;
        const transactionResponse = await this.extensionContract.mint();
        this.isWaitingForMint = false;
        this.redeemTransastionHash = transactionResponse.hash.toString();

        this.isRedeeming = true;
        await transactionResponse.wait(1);
        this.isRedeeming = false;

        this.didRedeem = true;
        this.changeTab(2);

        // update how many NFTs we think they own now
        await this.findNFTs();
      }
    } catch (error: any) {
      console.warn(error.code);
      console.warn(error.message);

      const transactionErrors = web3TransactionErrorHandling(error);
      if (error.message.includes('execution reverted: Inactive')) {
        this.errorMessage = 'Burn redeem not active';
      } else if (transactionErrors === TransactionErrors.REJECTED) {
        this.errorMessage = 'You rejected the tx. Try again whenever you are ready!';
      } else if (transactionErrors === TransactionErrors.REPLACED) {
        if (this.network && parseInt(this.network) === 1) {
          this.errorMessage = 'You sped up or cancelled your tx! Please reload the page.';
        }
      } else if (transactionErrors === TransactionErrors.CANCELLED) {
        this.errorMessage = 'You cancelled your tx. You can try again anytime.';
      } else if (transactionErrors === TransactionErrors.EXCEPTION) {
        this.errorMessage = 'Transaction failed. Please take a screenshot and contact support.';
      } else if (transactionErrors === TransactionErrors.LEDGER_ERROR) {
        this.errorMessage = 'Transaction rejected due to your Ledger. Ensure it is unlocked and updated.';
      }
    } finally {
      this.isRedeeming = false;
      this.isApproving = false;
      this.isRequesting = false;
    }
  }

  /**
   * Gives us the URL to inspec the given tx on etherscan
   * Switches to rinkeby in dev
   */
  getEtherscanURL (hash: string) : string {
    if (process && process.env.NODE_ENV === 'development') {
      return `https://rinkeby.etherscan.io/tx/${hash}`;
    } else {
      return `https://etherscan.io/tx/${hash}`;
    }
  }

  getIconText () : string {
    return this.account ? 'Disconnect Metamask' : 'Connect to Metamask';
  }
}
