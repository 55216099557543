import * as dotenv from 'dotenv';

dotenv.config();

export const FALLBACK_PROVIDER = process.env.VUE_APP_FALLBACK_PROVIDER;
export const BACKEND_URL = process.env.VUE_APP_BACKEND_URL;
export const NETWORK = process.env.VUE_APP_NETWORK;
export const EXTENSION_CONTRACT_ADDRESS = process.env.VUE_APP_EXTENSION_ADDRESS;
export const CREATOR_CONTRACT_ADDRESS = process.env.VUE_APP_CREATOR_CONTRACT_ADDRESS;
export const BURNABLE_KISS_ID = process.env.VUE_APP_BURNABLE_KISS_ID;
